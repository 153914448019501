let data = [
  {
    title: 'v-factory',
    duration: '2023.05 ~ 2023.05',
    image: [
      require('../assets/portfolio/v-factory/v-factory01.jpg'),
      require('../assets/portfolio/v-factory/v-factory02.jpg'),
      require('../assets/portfolio/v-factory/v-factory03.jpg'),
      require('../assets/portfolio/v-factory/v-factory04.jpg'),
      require('../assets/portfolio/v-factory/v-factory05.jpg'),
      require('../assets/portfolio/v-factory/v-factory06.jpg'),
      require('../assets/portfolio/v-factory/v-factory07.jpg'),
      require('../assets/portfolio/v-factory/v-factory08.jpg'),
    ],
    link: '',
    text: '회사 내에서 운영중인 플랫폼 내 상품들을 판매하기 위해 인플루언서들에게 공동구매를 제안할 목적으로 제작한 프로젝트로, 각 인플루언서별로 발급된 코드를 통해 사이트 로그인시 공동구매를 제안하는 상품들 리스트와, 상품에 대한 여러 상세 정보, 커미션 등을 안내해주는 사이트입니다. 프론트엔드 부분을 혼자서 담당했습니다.',
  },
  {
    title: 'Ecopro-Partners',
    duration: '2023.04 ~ 2023.05',
    image: [
      require('../assets/portfolio/ecopro-partners/ecopro-partners01.jpg'),
      require('../assets/portfolio/ecopro-partners/ecopro-partners02.jpg'),
      require('../assets/portfolio/ecopro-partners/ecopro-partners03.jpg'),
      require('../assets/portfolio/ecopro-partners/ecopro-partners04.jpg'),
      require('../assets/portfolio/ecopro-partners/ecopro-partners05.jpg'),
    ],
    link: '',
    text: '에코프로 파트너스 사의 웹사이트 제작 외주를 맡아 작업한 프로젝트입니다. 프론트엔드 부분을 혼자서 담당했습니다.',
  },
  {
    title: 'ONDOC',
    duration: '2023.01 ~ 2023.02',
    image: [
      require('../assets/portfolio/ondoc/ondoc01.jpg'),
      require('../assets/portfolio/ondoc/ondoc02.jpg'),
      require('../assets/portfolio/ondoc/ondoc03.jpg'),
      require('../assets/portfolio/ondoc/ondoc04.jpg'),
      require('../assets/portfolio/ondoc/ondoc05.jpg'),
      require('../assets/portfolio/ondoc/ondoc06.jpg'),
      require('../assets/portfolio/ondoc/ondoc07.jpg'),
      require('../assets/portfolio/ondoc/ondoc08.jpg'),
    ],
    link: 'https://ondoc.co.kr',
    text: '의사와 비만 환자들을 연결하여 온라인으로 간편하게 비대면 진료 및 처방을 받을 수 있도록 도와주는 플랫폼입니다.<br />이전 회사에서 실제로 운영중인 플랫폼으로, 회원, 결제 등의 이커머스 사이트의 일반적인 기능이 대부분 포함되어 있습니다.<br />맨처음 제작부터 23년 9월까지의 모든 유지보수를 담당했습니다.(프론트엔드 부분)',
  },
  {
    title: 'space-fighter',
    duration: '2023.08 ~ 2023.09',
    image: [
      require('../assets/portfolio/space-fighter/space-fighter01.jpg'),
      require('../assets/portfolio/space-fighter/space-fighter02.png'),
      require('../assets/portfolio/space-fighter/space-fighter03.png'),
      require('../assets/portfolio/space-fighter/space-fighter04.png'),
      require('../assets/portfolio/space-fighter/space-fighter05.png'),
      require('../assets/portfolio/space-fighter/space-fighter06.png'),
      require('../assets/portfolio/space-fighter/space-fighter07.png'),
    ],
    link: '',
    text: 'socket.io를 활용한 온라인 격투게임입니다. 방 접속, 채팅, 캐릭터 이동, 타격 등의 기능들이 담겨있습니다. socket을 활용한 실시간 데이터 공유 기능을 활용해 보고 싶어 토이 프로젝트로 제작하게 되었습니다. node.js와 React를 이용해 혼자서 작업하였습니다.',
  },
  {
    title: 'shorton',
    duration: '2022.09 ~ 2022.12',
    image: [
      require('../assets/portfolio/shorton/shorton01.jpg'),
      require('../assets/portfolio/shorton/shorton02.jpg'),
      require('../assets/portfolio/shorton/shorton03.jpg'),
      require('../assets/portfolio/shorton/shorton04.jpg'),
      require('../assets/portfolio/shorton/shorton05.jpg'),
      require('../assets/portfolio/shorton/shorton06.jpg'),
      require('../assets/portfolio/shorton/shorton07.jpg'),
      require('../assets/portfolio/shorton/shorton08.jpg'),
    ],
    link: '',
    text: '라이브 커머스, 쇼츠 등이 결합되어 사용자들이 여러 라이브 영상과 상품 관련 숏츠 영상을 간편하게 즐길 수 있고, 카테고리별 다양한 상품들을 구매할 수 있는 플랫폼입니다. 회사 사정으로 인해 프로젝트 마무리 이후 오픈되지 못해 아쉬움이 많이 남는 프로젝트입니다. 프론트엔드 부분을 혼자서 담당하였습니다.',
  },
  {
    title: 'Pettitude',
    duration: '2022.08 ~ 2022.09',
    image: [require('../assets/portfolio/pettitude/pettitude01.jpg')],
    link: 'https://pettitude.co.kr',
    text: '애완동물 용품 판매 사이트로, 퍼블리싱을 담당했습니다.',
  },
  {
    title: 'Reqgen',
    duration: '2022.07 ~ 2022.07',
    image: [require('../assets/portfolio/reqgen/reqgen01.jpg')],
    link: 'https://reqgen.com',
    text: '유산균 등 건강기능식품을 판매하는 사이트로, 퍼블리싱을 담당했습니다.',
  },

  {
    title: 'rba-people',
    duration: '2022.04 ~ 2022.05',
    image: [require('../assets/portfolio/rba-people/rba-people01.jpg')],
    link: '',
    text: '노동자들의 커뮤니티 사이트 컨셉으로 제작한 토이 프로젝트입니다. AWS RDS, React, Mongo DB, express.js를 사용하여 처음부터 끝까지 혼자서 제작한 첫 사이트입니다.',
  },
];
export default data;
